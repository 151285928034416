"use strict";

angular.module("informaApp")
    .service("ChartElementsHelper", ["d3", function (d3) {
        var result = {};

        result.colors = [
            "#E52562", "#C65FD3", "#F9713D", "#7D2CB9", "#F8476E", "#FBC22C", "#1D87B4",
            "#53B342", "#BA2D7F", "#B84730", "#4790D9", "#994499", "#22AA99", "#AAAA11",
            "#6633CC", "#E67300", "#8B0707", "#329262", "#5574A6", "#4E48F0", "#452e5e",
            "#BDC914", "#B06CF5", "#A84040"];

        result.status = {
            colors: {
                success: "#21AE4B",
                failed: "#df2020",
                inProgress: "#9F9F00"
            },
            icons: {
                success: "\ue013",
                failed: "\ue014",
                inProgress: "\ue023"
            }
        };

        result.getStatusColor = getStatusColor;
        result.getIconByStatus = getIconByStatus;

        function getStatusColor(success) {
            return success == null ? result.status.colors.inProgress : (success === false ? result.status.colors.failed : result.status.colors.success);
        }

        function getIconByStatus(success) {
            return success == null ? result.status.icons.inProgress : (success === false ? result.status.icons.failed : result.status.icons.success);
        }

        result.translate = function (x, y) {
            return 'translate(' + x + ', ' + y + ')';
        };

        result.wrapWord = function (text, width) {
            text.each(function () {
                var text = d3.select(this);
                // var text = originalText.replace(/\//g, " ");
                var textString = text.text().replace(/\//g, "/ ");

                var words = textString.split(/[\s,]+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = text.attr("y"),
                    dx = parseFloat(text.attr("dx")),
                    dy = parseFloat(text.attr("dy")),
                    tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "px");

                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dx", dx + "px").attr("dy", ++lineNumber * lineHeight + "em").text(word);
                    }
                }
            });
        };

        result.addBorderForText = function (element) {
            var textShadow = '2px 0 0 white, -2px 0 0 white, 0 2px 0 white, 0 -2px 0 white, 1px 1px white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white';

            element.style('text-shadow', textShadow);
        }

        result.removeOverlapping = function (labels, anchors, labelRects, width, height) {
            var anchorRects = labelRects.map(createAnchorFromLabel);

            d3.labeler()
                .label(labelRects)
                .anchor(anchorRects)
                .width(width)
                .height(height)
                .start(2000);

            labels
                .attr('x', function (d) {
                    return (d.x);
                })
                .attr('y', function (d) {
                    return (d.y);
                });

            anchors
                .attr('x2', function (d) {
                    return (d.x);
                })
                .attr('y2', function (d) {
                    return (d.y);
                });

            function createAnchorFromLabel(label) {
                var anchorRadius = 10;

                return {
                    x: label.x,
                    y: label.y,
                    r: anchorRadius
                };
            }
        };

        return result;
    }]);